<template>
  <div
    v-if="settings"
    class="template-view rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-lg"
    :style="`min-height: ${($vuetify.breakpoint.height - 72).toString() + 'px'}`"
  >
    <TemplateQuestionnaireForm
      :workbench="workbench"
      :template-data="template"
      :current-case-form="currentCaseForm"
      :options="{
        hasClose: true,
        inCase: false,
        inCaseModel: true,
        inTemplate: false
      }"
      @update-template-name="updateActualTemplateName"
      @save="saveTemplate"
      @addField="addNewQuestionnaireFieldInCaseModel"
      @updateField="updateCaseModelQuestionnaireField"
      @change-field-order="updateQuestionnaireFieldsOrder"
      @deleteField="deleteFieldInCaseModelQuestionnaire"
      @closeTemplate="$router.back()"
      @updateQuestionnaire="updateQuestionnaire"
    />
  </div>
</template>

<script>
import TemplateQuestionnaireForm from '@/components/forms/TemplateQuestionnaireForm.vue'
import templateViewCaseModelsMixin from './CaseModelMixins/templateViewCaseModelsMixin'

export default {
  components: {
    TemplateQuestionnaireForm
  },
  mixins: [templateViewCaseModelsMixin]
}
</script>

<style lang="scss" scoped>
  .template-view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    z-index: 1;
  }
</style>
